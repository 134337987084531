import { useQuery } from '@apollo/client';
import { QUERY_TASKS } from '../../utils/queries';
import Logo from './logo192.png';
import Task from './Task';
import Auth from '../../utils/auth';

const TaskList = () => {
    const email = Auth.getProfile().data.email;
    
    const { loading, error, data } = useQuery(QUERY_TASKS, { variables: { taskOwner: email } });
    if (loading) { return (<div></div>) }

    var taskList = data.getTasks;
    const options = { month: "long", day: "numeric", year: "numeric" };
    
    var tasks = [];
    var sortedTasks = [];

    if (taskList) {
        sortedTasks = taskList.toSorted((a, b) => {
            return new Date(a.dueDate) - new Date(b.dueDate);
        });

        for (let i = 0; i < sortedTasks.length; i++) {
            const dueDate = new Date(sortedTasks[i].dueDate).toLocaleDateString("en-US", options);
            tasks.push(<Task key={i + 1} id={sortedTasks[i].id} symbol={Logo}
                taskName={sortedTasks[i].taskName}
                taskName2={sortedTasks[i].taskName2}
                taskDueDate={dueDate} />)
        }
    }



    return (
        <div className="task-list">
            {tasks}
        </div>
    );
}

export default TaskList;