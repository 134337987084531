import "./HomeContent.css";

const HomeContent = () => {
    return (
        <div className="public-homepage">
            <h2>Welcome to OnusTask!</h2>
            <p>OnusTask helps you manage your day with ease. Organize your tasks, prioritize your work, and get more done every day.</p>

            <div className="features">
                <h3>Features:</h3>
                <ul>
                    <li>✔️ Create, track, and complete tasks.</li>
                    <li>✔️ Set due dates and receive reminders.</li>
                    <li>✔️ Organize tasks by categories.</li>
                    <li>✔️ View your progress with visual indicators.</li>
                </ul>
            </div>

            <p>Sign up now to start managing your tasks and achieve your goals!</p>

        </div>
    );
}

export default HomeContent;